import React, { useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Box,
  Text,
  Button,
  VStack,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { exportAsPDF, exportAsExcel } from "./exportFunctions";
import axios from "axios";
import { baseUrl } from "../../../apiCalls/config";
import UpdatePaymentAmountModal from "./UpdatePaymentAmountModal ";

const PaymentDetailsDrawer = ({
  isOpen,
  onClose,
  payment,
}) => {
  const {
    isOpen: isExportModalOpen,
    onOpen: openExportModal,
    onClose: closeExportModal,
  } = useDisclosure();

  const {
    isOpen: isRefundModalOpen,
    onOpen: openRefundModal,
    onClose: closeRefundModal,
  } = useDisclosure();

  const {
    isOpen: isUpdateModalOpen,
    onOpen: openUpdateModal,
    onClose: closeUpdateModal,
  } = useDisclosure();

  const [refundAmount, setRefundAmount] = useState(0);
  const [refundReason, setRefundReason] = useState("");
  const toast = useToast();
 
  const handleExportClick = (format) => {
    if (format === "pdf") {
      exportAsPDF(payment);
    } else if (format === "excel") {
      exportAsExcel(payment);
    }
    closeExportModal();
  };

  const handleUpdateAmountClose = () => {
    closeUpdateModal();
  };

  const handleRefundSubmit = async () => {
    if (!validateRefundFields()) {
      return;
    }
  
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login.");
      window.location.href = "/login";
      return;
    }
  
    try {
      const response = await axios.post(
        `${baseUrl}/api/admin/refund`,
        {
          reasonForRefund: refundReason,
          refundAmount,
          appointmentId: payment.appointmentId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.data.success) {
        toast({
          title: "Refund Processed",
          description: "The refund has been processed successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        closeRefundModal();
        onClose();
      } else {
        throw new Error(
          response.data.message || "Failed to process the refund."
        );
      }
    } catch (error) {
      toast({
        title: "Error",
        description:
          error.message || "An error occurred while processing the refund.",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top-right",
      });
    }
  };
  

  if (!payment) return null;

  const validateRefundFields = () => {
    if (!refundAmount || !refundReason) {
      toast({
        title: "Required Fields",
        description: "Please fill in all fields.",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top-left",
      });
      return false;
    }
    return true;
  };

  const formatAmount = (amount) => {
    const num = Number(amount);
    return num.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };
  


  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent bg="#4B4B4B" color="white">
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            Payment Details
            <Button
              color="red.200"
              ml={20}
              onClick={openRefundModal}
              size="sm"
              variant="outline"
            >
              Trigger Refund
            </Button>
          </DrawerHeader>

          <DrawerBody>
            <VStack align="left" spacing={4}>
              {/* Payment details here */}
              <Box>
                <Text fontWeight="bold">Customer Name:</Text>
                <Text>{payment?.customerName || "N/A"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Payment ID:</Text>
                <Text>{payment.id || "N/A"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Appointment ID:</Text>
                <Text>{payment.appointmentId || "N/A"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Transaction ID:</Text>
                <Text>{payment.transactionId || "N/A"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Currency:</Text>
                <Text>{payment.currency || "N/A"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Amount:</Text>
                <Text>₦{formatAmount(payment.amount) || "N/A"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Balance:</Text>
                <Text>
                  {payment.balance
                    ? `₦${formatAmount(payment.balance)}`
                    : "N/A"}
                </Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Payment for appointment:</Text>
                {
                  payment.serviceCharge ? "Yes" : "No"}
              </Box>
              <Box>
                <Text fontWeight="bold">Payment for Medic:</Text>
                {
                  payment.costOfService ? "Yes" : "No"}
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Paid At:</Text>
                <Text>
                  {payment.createdAt
                    ? new Date(payment.createdAt).toLocaleString()
                    : "N/A"}
                </Text>
              </Box>
              <Box>
                <Text fontWeight="bold">Updated At:</Text>
                <Text>
                  {payment.updatedAt
                    ? new Date(payment.updatedAt).toLocaleString()
                    : "N/A"}
                </Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Subscription:</Text>
                <Text color={payment.subscription ? "green.200" : "yellow.200"}>
                  {
                  payment.subscription ? "Yes" : "No"}
                </Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Refunded:</Text>
                <Text>{payment.refunded ? "Yes" : "No"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Refund Amount:</Text>
                <Text>
                  {payment.refundAmount
                    ? `₦${payment.refundAmount.toLocaleString()}`
                    : "N/A"}
                </Text>
              </Box>
             
              <Divider />
              <Box>
                <Text fontWeight="bold">Reason for Refund:</Text>
                <Text>{payment.reasonForRefund || "N/A"}</Text>
              </Box>
              <Divider />
              <Box>
                <Text fontWeight="bold">Refund Date:</Text>
                <Text>
                  {payment.refundDate
                    ? new Date(payment.refundDate).toLocaleString()
                    : "N/A"}
                </Text>
              </Box>
            </VStack>
          </DrawerBody>

          <DrawerFooter justifyContent="space-between">
          <Button colorScheme="blue" mr={2} onClick={openUpdateModal}>
              Update Amount
            </Button>
            <Button colorScheme="green" onClick={openExportModal}>
              Export Receipt
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <UpdatePaymentAmountModal
        isOpen={isUpdateModalOpen}
        onClose={handleUpdateAmountClose}
        appointmentId={payment.appointmentId}
      />

      {/* Export Modal */}
      <Modal isOpen={isExportModalOpen} onClose={closeExportModal}>
        <ModalOverlay />
        <ModalContent bg="#4B4B4B" color="white">
          <ModalHeader>Export Receipt</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Select the format to export the receipt:</Text>
          </ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button
              colorScheme="green"
              onClick={() => handleExportClick("pdf")}
            >
              Export as PDF
            </Button>
            <Button
              colorScheme="blue"
              onClick={() => handleExportClick("excel")}
            >
              Export as Excel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Refund Modal */}
      <Modal isOpen={isRefundModalOpen} onClose={closeRefundModal}>
        <ModalOverlay />
        <ModalContent bg="#4B4B4B" color="white">
          <ModalHeader>Trigger Refund</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={4}>
              <Text fontWeight="bold">Appointment ID:</Text>
              <Text>{payment.transactionId}</Text>
            </Box>
            <Box mb={4}>
              <Text fontWeight="bold">Refund Amount (₦):</Text>
              <Input
                variant="outline"
                type="number"
                value={refundAmount}
                onChange={(e) => setRefundAmount(e.target.value)}
                bg="#4B4B4B"
                color="white"
                isRequired 
              />
            </Box>
            <Box>
              <Text fontWeight="bold">Reason for Refund:</Text>
              <Textarea
                variant="outline"
                value={refundReason}
                onChange={(e) => setRefundReason(e.target.value)}
                rows={4}
                bg="#4B4B4B"
                color="white"
                isRequired 
              />
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              color="red.200"
              variant="outline"
              onClick={handleRefundSubmit}
              isLoading={false}
              loadingText="Processing"
            >
              Process Refund
            </Button>
            <Button
              variant="outline"
              colorScheme="white"
              ml={3}
              onClick={closeRefundModal}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PaymentDetailsDrawer;
